<template>
  <div v-loading="loading" style="margin-left: 20px">
    <div v-if="!loading">
      <div class v-if="isCurrentSlugPresent">
        <div v-if="getCurrentStep">
          <template>
            <el-row>
              <el-col :span="24">
                <PreviewTemplate
                  :templateData="getCurrentStep.templateInfo"
                  :has-next="getCurrentStep.has_next"
                  :entityDataId="entityDataId"
                  :workflow-step-id="getCurrentStep._id"
                  :template-data-id="getCurrentStep.templateDataId"
                  :is-execute="true"
                  :is-edit="isEdit"
                />
              </el-col>
              <!-- <el-dialog
                top="0"
                :fullscreen="true"
                :visible.sync="showRepeatingTemplatesData"
              >
                <RepeatableTemplatesData
                  v-if="showRepeatingTemplatesData"
                  :templateData="getCurrentStep.template"
                  :workflow-data-id="entityDataId"
                ></RepeatableTemplatesData>
              </el-dialog> -->
            </el-row>
          </template>
        </div>
      </div>
      <div v-else-if="isCurrentSlugIsRelationship">
        <EntityReationshipDataTable
          :relationshipDetails="getCurrentRelationshipDetails"
          :nextRealationshipDetails="hasNextRelationshipDetails"
        />
      </div>

      <div v-else-if="isCurrentSlugIsNestedRelationship">
        <EntityNestedReationshipDataTable
          :relationshipDetails="getCurrentNestedRelationshipDetails"
          :nextRealationshipDetails="null"
        />
      </div>

      <div v-else>
        <div
          v-if="
            templateKey === 'contacts' &&
            getEntityDataById.entity_type != 'INDIVIDUAL'
          "
        >
          <EntityContacts
            :contactType="entityId"
            :accountName="entityDataId"
            :entityDataId="getEntityDataById"
          />
          <!-- Contacts -->
          <!-- <PreviewTemplate
            :templateData="getCurrentStep.templateInfo"
            :has-next="getCurrentStep.has_next"
            :entityDataId="entityDataId"
          /> -->
        </div>

        <div v-else style="text-align: center; color: red">
          <h3>Invalid step</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PreviewTemplate from "./../templates/PreviewTemplate";
// import RepeatableTemplatesData from "./../templates/RepeatableTemplatesData";
import EntityReationshipDataTable from "./EntityReationshipDataTable.vue";
import EntityContacts from "./EntityContacts.vue";
import EntityNestedReationshipDataTable from "./EntityNestedReationshipDataTable.vue";

import { mapGetters } from "vuex";

export default {
  name: "Entity-EntityExecuteStep",
  components: {
    PreviewTemplate,
    EntityReationshipDataTable,
    EntityContacts,
    EntityNestedReationshipDataTable,
    // RepeatableTemplatesData,
  },
  data() {
    return {
      entityId: null,
      currentSlug: null,
      loading: false,
      entityDataId: null,
      showRepeatingTemplatesData: false,
      refreshTemplate: false,
      isEdit: false,
      currentRelationshipDetails: {},
      currentEntityNestedRelationships: {},
      templateKey: "",
      currentRelationshipIndex: -1,
      hasNextRelationshipDetails: null,
    };
  },
  async mounted() {},
  created() {
    this.getNecessaryInfo();
  },
  computed: {
    ...mapGetters("entities", ["getEntityDataById"]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getCurrentEntityRelationships",
      "getCurrentEntityNestedRelationships",
    ]),
    ...mapGetters("filters", ["getSingleFilterData", "getAllEntityFilters"]),
    slugs() {
      if (
        this.getEntityDataById &&
        this.getEntityDataById.templates &&
        this.getEntityDataById.templates.length
      ) {
        return this.getEntityDataById.templates.map(
          (step) => step.templateInfo.slug
        );
      }
      return [];
    },
    isCurrentSlugPresent() {
      if (this.slugs && this.slugs.length) {
        if (this.slugs.indexOf(this.currentSlug) === -1) {
          return false;
        }
        return true;
      }
      return true;
    },
    getCurrentEntityTemplates(){
      if (this.getEntityDataById && this.getEntityDataById.templates) {
        if (
          this.$route.query?.filter &&
          this.getSingleFilterData?.permissions?.templates &&
          this.getEntityDataById._id == this.getSingleFilterData.entity_id
        ) {
          return this.getEntityDataById.templates.filter(
            (e) =>
              this.getSingleFilterData?.permissions?.templates.indexOf(
                e.template_id
              ) != -1
          );
        }
        return this.getEntityDataById.templates;
      }
      return []
    },
    getStepsObject() {
      if (
        this.getCurrentEntityTemplates &&
        this.getCurrentEntityTemplates.length
      ) {
        const data = {};
        this.getCurrentEntityTemplates.forEach((step, index, arr) => {
          // it will use to determine finish step
          step.has_next = index < arr.length - 1;
          // step.templateDataId = this.getTemplateDataIdMap[step.template_id]?this.getTemplateDataIdMap[step.template_id]:null;

          data[step.templateInfo.slug] = step;
        });
        return data;
      }
      return {};
    },

    getCurrentStep() {
      if (this.getStepsObject && this.currentSlug) {
        const step = this.getStepsObject[this.currentSlug];
        if (step && step.type !== "TEMPLATE") {
          return step;
        }
        return step;
      }

      return null;
    },

    isCurrentSlugIsRelationship() {
      let relationship = this.$route.query.relationship_id;
      if (relationship) {
        return (
          this.currentRelationshipDetails &&
          this.currentRelationshipDetails.relationship_id == relationship
        );
      }
      return false;
    },

    isCurrentSlugIsNestedRelationship() {
      let relationship = this.$route.query.relationship_id;
      return (
        relationship &&
        this.currentEntityNestedRelationships &&
        this.currentEntityNestedRelationships.relationship_id == relationship
      );
    },

    getCurrentRelationshipDetails() {
      return this.currentRelationshipDetails;
    },
    getCurrentNestedRelationshipDetails() {
      return this.currentEntityNestedRelationships;
    },
  },
  methods: {
    async getNecessaryInfo() {
      this.templateKey = this.$route.query.templateKey || null;
      if (
        this.$route.name === "EntityDataEdit" ||
        this.$route.name === "EntityDataEditStep"
      ) {
        this.isEdit = true;
      } else {
        this.isEdit = false;
      }
      this.currentSlug = this.$route.params.template_code;
      this.entityDataId = this.$route.query.dataId || null;
      this.loading = true;
      this.step = this.$route.query.step || 1;
      this.entityId = this.$route.params.entity_id;
      this.loading = false;

      if (this.entityDataId) {
        this.loading = true;
        this.step = this.$route.query.step || 1;

        this.loading = false;
      }
    },
  },
  watch: {
    "$route.params.slug"(slug) {
      this.currentSlug = slug;
    },

    "$route.params.template_code": {
      handler: function (slug) {
        this.currentSlug = slug;
        if (this.$route.query.relationship_id) {
          this.currentRelationshipIndex = [
            ...this.getCurrentEntityRelationships,
          ].findIndex((e) => e.relationship_slug == slug);

          this.currentRelationshipDetails = [
            ...this.getCurrentEntityRelationships,
          ].find((e) => e.relationship_slug == slug);

          this.currentEntityNestedRelationships = [
            ...this.getCurrentEntityNestedRelationships,
          ].find((e) => e.relationship_slug == slug);

          if (
            this.currentRelationshipIndex > -1 &&
            this.currentRelationshipIndex + 1 <=
              this.getCurrentEntityRelationships.length - 1
          ) {
            this.hasNextRelationshipDetails =
              this.getCurrentEntityRelationships[
                this.currentRelationshipIndex + 1
              ];
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
</style>